<template>
  <v-container fluid>
    <template>
      <v-row
        class="px-lg-12"
        style="font-size: 18px; font-weight: 500;"
      >
        <v-col
          sm="6"
          md="6"
        >
          <p>
            Xendit Balance
            :
            Rp. {{ xenditBalance | dotThousandSeparator }}
          </p>
        </v-col>
      </v-row>

      <v-row class="px-lg-10">
        <v-col>
          <div>
            <v-tabs
              v-model="tab"
              centered
              color="purple"
              mobile-breakpoint="100"
            >
              <v-tab
                key="ALL"
                @change="onChangeTabs('ALL')"
              >
                SEMUA
              </v-tab>
              <v-tab
                key="NEW"
                @change="onChangeTabs('NEW')"
              >
                BARU
              </v-tab>
              <v-tab
                key="REJECT"
                @change="onChangeTabs('REJECT')"
              >
                DITOLAK
              </v-tab>
              <v-tab
                key="DONE"
                @change="onChangeTabs('DONE')"
              >
                SELESAI
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab"
              touchless
            >
              <v-tab-item
                key="ALL"
                :eager="true"
              >
                <withdrawal-view-all ref="ALL" />
              </v-tab-item>
              <v-tab-item
                key="NEW"
                :eager="true"
              >
                <withdrawal-view-new ref="NEW" />
              </v-tab-item>
              <v-tab-item
                key="REJECT"
                :eager="true"
              >
                <withdrawal-view-reject ref="REJECT" />
              </v-tab-item>
              <v-tab-item
                key="DONE"
                :eager="true"
              >
                <withdrawal-view-done ref="DONE" />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      WithdrawalViewAll: () => import('./WithdrawalViewAll'),
      WithdrawalViewNew: () => import('./WithdrawalViewNew'),
      WithdrawalViewReject: () => import('./WithdrawalViewReject'),
      WithdrawalViewDone: () => import('./WithdrawalViewDone'),
    },
    data: () => ({
      tab: null,
      xenditBalance: 0,
    }),

    computed: {
      ...mapState({
        name: state => state.withdrawal.name,
        drawer: state => state.setting.drawer,
      }),
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        const vm = this
        const requestBody = {}
        axios.post('/t/xendit/balance', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.xenditBalance = res.data.data.xendit_balance
          }
        }).catch((e) => {
        })
      },

      editButtonClick (item) {
        this.$router.push({ name: 'WithdrawalDetail', params: { id: item.hash } })
      },

      onChangeTabs (key) {
        switch (key) {
          case 'ALL':
            this.$refs.ALL.initialize()
            break
          case 'NEW':
            this.$refs.NEW.initialize()
            break
          case 'ACC':
            this.$refs.ACC.initialize()
            break
          case 'REJECT':
            this.$refs.REJECT.initialize()
            break
          case 'DONE':
            this.$refs.DONE.initialize()
        }
      },
    },
  }
</script>

<style scope>
</style>
